<template>
    <div>
        <Steps
            v-if="pages > 0"
            :model="steps"
            v-model:activeStep="tabIndex"
            class="custom-steps p-mb-2"
            :class="{'no-before': pages === 1}"
            :readonly="false">
            <template #item="{ item, active }">
                <span
                    :class="[`inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer ${item.class}`, { 'bg-primary': active, 'surface-overlay text-primary': !active }]">
                    {{ item.label }}
                </span>
            </template>
        </Steps>
        <ProgressBar
            :value="progress"
            v-if="showProgress"></ProgressBar>
        <div v-if="showInfo">
            <hr />
            <h4 class="p-mt-0">{{ metadata.businessName || '' }} <small v-if="metadata.company">by {{ metadata.company }}</small></h4>
            <p v-if="metadata.office || metadata.email">
                {{ metadata.office || '' }}
                <template v-if="metadata.office && metadata.email"> | </template>
                {{ metadata.email || '' }}
            </p>
            <template v-if="metadata.company || metadata.office || metadata.email"><hr  /></template>
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store";

export default {
    name: "Header",
    setup() {
        return {
            state: useStore().state,
            setState: useStore().setState,
        };
    },
    created() {
    },
    props: {
        metadata: {
            type: Object,
            default: function () {
                return {}
            },
        },
        questions: {
            type: Array,
            default: function () {
                return []
            },
        },
        groupedQuestions: {
            type: Array,
            default: function () {
                return []
            },
        },
        pages: {
            type: Number,
            default: 1,
        },
        page: {
            type: Number,
            default: 1,
        },
        showProgress: {
            type: Boolean,
            default: true,
        },
        showInfo: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    updated() {},
    watch: {
    },
    computed: {
        tabIndex: {
            get () {
                return this.page - 1
            },
            set (value) {
                this.$emit('onPageSelected', value)
            }
        },
        steps () {
            // INIT
            const arr = []

            // ITERATE
            for (let i = 0; i < this.groupedQuestions.length; i++) {
                const group = this.groupedQuestions[i]
                const item = {
                    label: (i + 1),
                    class: this.getStepClass(group),
                    active: i === 0
                }
                arr.push(item)
            }

            // RETURN
            return arr
        },
        progress () {
            // INIT
            const max = this.questions.length
            let value = 0

            // ITERATE
            for (let i = 0; i < this.questions.length; i++) {
                const answer = this.questions[i].answer
                if (!this.isEmptyObject(answer)) {
                    value++
                }
            }

            // RETURN
            const val = (value / max * 100).toFixed(1)
            return parseFloat(val)
        }
    },
    methods: {
        getStepClass (group) {
            // INIT
            const count = group.length
            let empty = 0
            let full = 0
            let className = ''

            // ITERATE
            for (let i = 0; i < group.length; i++) {
                const answer = group[i].answer
                if (this.isEmptyObject(answer)) {
                    empty++
                } else {
                    full++
                }
            }

            // CHECK
            if (empty > 0 && full > 0) className = 'partial'
            if (empty === count) className = ''
            if (full === count) className = 'full'

            // RETURN
            return className
        },
        isEmptyObject (obj) {
            return obj === null || (Object.keys(obj).length === 0 && obj.constructor === Object)
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
