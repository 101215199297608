/**
 * USAGE:
 *
 * Simple:
 * $t("general.name")
 *
 * Composed:
 * $t("composed.version", { version: "2.0" })
 */

export default {
  notification_alert: `I servizi CTI e DTI sono in manutenzione e al momento non sono funzionanti. Non appena il servizio verrà ripristinato, sarà segnalato qui.`,
  expired_license: "Licenza scaduta o non sufficiente per il servizio!",
  general: {
    name: `Nome`,
    surname: `Cognome`,
    email: `Email`,
    description: `Descrizione`,
    phone: `Telefono`,
    company: `Azienda`,
    company_url: `URL`,
    incident_type: `Tipologia dell'incidente`,
    business_name: `Ragione sociale`,
    company_name: `Nome azienda`,
    project_name: `Nome progetto`,
    address: `Indirizzo`,
    add: `Aggiungi`,
    save: `Salva`,
    next: `Continua`,
    back: `Indietro`,
    abort: `Annulla`,
    dismiss: `Chiudi`,
    close: `Chiudi`,
    Engine: `Engine | Engines`,
    Report: `Report | Reports`,
    Target: `Target | Targets`,
    run_test: `Esegui test`,
    edit: `Modifica`,
    show_reports: `Mostra reports`,
    make_new_test: `Esegui nuovo test`,
    add_new: `Aggiungi nuovo`,
    target_info: `Target Info`,
    scan_type: `Tipo`,
    delete: `Elimina`,
    confirmation_required: `Conferma richiesta`,
    No: `No`,
    Additional_info: `Informazioni aggiuntive`,
    global_search: `Ricerca Globale`,
    date: `Data`,
    status: `Stato`,
    view: `Dettaglio`,
    no_reports: `Nessun report presente`,
    no_pending_reports: `Nessun report pendente`,
    Pending_reports: `Reports pendenti`,
    all_severities: `Tutte le Severità`,
    critical_severity: `Severità Critica`,
    high_severity: `Alta Severità`,
    medium_severity: `Media Severità`,
    low_severity: `Bassa Severità`,
    none_severity: `Severità Nulla`,
    informational_severity: `Informational Severità`,
    ip_address: `Indirizzo IP`,
    total_potential_vulnerabilities: `Vulnerabilità potenziali totali`,
    compromised_emails_others: `Email compromesse dominio specifico / Altri`,
    total_breach: `Emails compromesse`,
    total_breach_source: `Fonti delle Breach`,
    total_availability_impact: `Impatto totale sulla availability`,
    total_confidentiality_impact: `Impatto totale sulla Confidentiality`,
    total_integrity_impact: `Impatto totale sull'integrity`,
    confidentiality: `Confidentiality`,
    availability: `Availability`,
    integrity: `Integrity`,
    subdomain: `Sottodominio | Sottodomini`,
    detail: `Dettaglio | Dettagli`,
    potential_vulnerabilities: `Vulnerabilità potenziali`,
    port: `Porta | Porte`,
    critical: `Critica`,
    high: `Alta`,
    medium: `Media`,
    low: `Bassa`,
    informational: `Informational`,
    none: `Nessuna`,
    ok: `OK`,
    ip_list: `Elenco ip`,
    technologies: `Tecnologie`,
    info: `Info`,
    error: `Errore`,
    message: `Messaggio`,
    start_scan_at: `Scan iniziato alle`,
    end_scan_at: `Scan finito alle`,
    vulnerabilities: `Vulnerabilità`,
    severity: `Severità`,
    total: `Totale`,
    risk: `Rischio`,
    technology_risk: `Rischio Tecnologico`,
    human_risk: "Human Risk",
    gdpr_risk: `GDPR Risk`,
    breach_source: `Severity delle Breach`,
    started: `Partito`,
    test_started: `Test partito correttamente`,
    scan_light: `Scansione Light`,
    scan_full: `Scansione Full`,
    contact_us: `Contattaci`,
    created_at: `Creato il`,
    download_brochure: "Scarica brochure",
    upgrade: `Upgrade`,
    free: `Gratuito`,
    send: `Invia`,
    scan_date: `Data scansione`,
    services: `Servizi`,
    value: `Valore`,
    choose_file: "Scegli file",
    delete_file: "Rimuovi file",
    warning: "Attenzione!",
    title: "Titolo",
    resource_name: "Nome risorsa",
    record_id: "ID record",
    language: "Lingua",
    issued_for: "Rilasciato per",
    issuer: "Emittente",
    reported_source: "Fonte segnalata",
    malicious_activity: "Attività dannosa",
    botnet: "Botnet",
    botnets: "Botnet",
    ports: "Porte",
    engine_description: "Servizio",
    details: "Dettagli",
    snippet: `Snippet`,
    higher_cvss: "CVSS più alta",
    registered: "Registrato",
    unregistered: "Non registrato",
    actions: "Azioni",
    requests_come_from: "Tutte le richieste arriveranno dal seguente IP: <strong>{ip}</strong>",
    accuracy: "Accuratezza",
    services_detection: "Services detection",
    probes_detection: "Probes detection",
    raw_data: "Raw data",
    no_alive_targets: "Nessun target raggiungibile",
    download_progress: "Download",
    botnets_count: "Conteggio botnet",
    findings: `Findings`,
    groups: `Gruppi`,
    sources: `Fonti`,
    go_on: "Prosegui",
    count: "Conteggio",
    enable: "Abilita/Disabilita",
    policy_privacy_link: `https://www.swascan.com/it/privacy-policy/`,
    ransomware_attack_index: `Ransomware Attack Index`,
    non_repudiation: `Non Repudiation`,
    access_control: `Access Control`,
    other: `Altro`,
    yes: "Si",
    no: "No",
    no_data_available: `Nessun dato disponibile`,
    common_name: `Server`,
    occurrence: `Num.`,
    start: `Avvia`,
    success_message: `Operazione completata con successo!`,
    under_attack: 'Sotto Attacco',
    settings: 'Impostazioni',
  },
  nscan: {
    address_range: `Range indirizzi`,
    start_address: `Indirizzo di partenza`,
    end_address: `Indirizzo di fine`,
  },
  composed: {
    version: (context) => `Platform version ${context.named("version")}`,
  },
  pages: {
    account: {
      account_type: "Tipo di Account",
      account_type_is: "Il tipo di account #[ENGINE_DESCR] è",
      contact: " Mettiti in contatto direttamente con noi o con uno dei nostri partner per fare l’upgrade del tuo account",
      contact_our_partner: "Contatta un nostro Partner",
    },
    dashboard: {
      total_scans: `Scansioni totali`,
      scans_done: `Scansioni utilizzate`,
      remaining_scans: `Scansioni rimanenti`,
      subscription_expiration: `Data di scadenza`,
      scans: `Scansioni`,
      usage: `Utilizzo`,
      license_type: `Tipo di licenza`,
    },
    targets: {
      table_head: `Lista target raggruppati per Engine`,
      table_head_engine: (context) => `Lista target ${context.named("engine")}`,
      add_new_target: `Aggiungi un nuovo target`,
      edit_target: `Modifica target`,
      delete_question: `Sei sicuro di voler cancellare questo target?`,
      stop_question: `Sei sicuro di voler fermare questo task?`,
      confirm_body: `Sei sicuro di voler lanciare questo test?`,
      processing: `Processo in corso...`,
      target_saved: `Target salvato`,
      target_extra_saved: `Dati extra del target salvati`,
      ipv4_ipv6_only: "Target can only contains ipv4 and url or ipv6",
      mixed_ip_error: "Target can only contains private or public ip. You can't mix them into a single target.",
      errors: {
        target_save: `Errore nel salvataggio del Target`,
        target_extra_save: `Errore nel salvataggio degli extra data del Target`,
        target_not_allowed: `Non sei autorizzato a creare nuovi target per questo servizio`,
      },
      new_test: {
        title: "Network Scan",
        select_scan_profile: "Seleziona il profilo di scansione",
        nscan_full: "Full Scan",
        nscan_medium: "Fast Scan",
        nscan_lite: "Lite Scan",
        nscan_log4shell: "Log4shell",
        rapid_scan: "Full Scan",
        general_scan: "Medium Scan",
        port_scan: "Port Discovery",
        service_scan: "Service Discovery",
        base_scan: "Light Scan",
        general_port_service_scan: "General Port and Service Discovery",
        host_discovery_scan: "Host Discovery",
        network_discovery_scan: "Network Discovery",
        indemnity: "Scansione sito: manleva",
        note: `(a) Dichiaro e garantisco di avere giusto titolo e legittimazione per acconsentire che il Servizio 
                    esegua la scansione di malware o altre vulnerabilità del Sito, individuate da Tinexta Cyber per la scansione, 
                    sia elettronicamente che con qualsiasi altro mezzo, sia durante la Registrazione iniziale o successivamente. 
                    Manleverai Tinexta Cyber, i suoi Rivenditori Autorizzati, partner e sponsor, e i loro funzionari, direttori, 
                    dipendenti e agenti da e contro qualsiasi pretesa, azione legale, responsabilità, perdita, danno, giudizio, 
                    domanda e/o pretesa giudiziale e/o extragiudiziale, multa, sanzione, i costi e le spese 
                    (comprese le ragionevoli spese legali) sostenute o addebitate allo stesso derivanti da o basate sull'utilizzo 
                    o l'impossibilità di utilizzare il Servizio da parte dell'utente, incluso qualsiasi reclamo derivante dalla 
                    violazione della presente Sezione 7 da parte dell'utente.<br>(b) Riconosco ed accetto inoltre che la scansione 
                    di tale Sito potrebbe esporre malware e altre vulnerabilità e, in alcune circostanze, potrebbe comportare 
                    l'interruzione dei servizi su tale Sito. A causa della natura della scansione, riconosco ed accetto che il 
                    Servizio comporta un rischio sostanziale e, di conseguenza,  assumo il rischio per tutti i danni, le perdite 
                    e le spese derivanti dal tuo utilizzo del Servizio.`,
        scan_full_and_fast: `Full and fast`,
        scan_full_and_fast_ultimate: `Full and fast ultimate`,
        scan_full_and_very_deep: `Full and very deep`,
        scan_full_and_very_deep_ultimate: `Full and very deep ultimate`,
      },
      new_webtest: {
        title: "WebApp Scan",
        scan_strength: "Scan Strength",
        scanner_alert_threshold: "Scanner Alert Threshold",
        authentication_method_name: "Metodo di autenticazione",
        default: "Default",
        low: "Low",
        medium: "Medium",
        high: "High",
        off: "Off",
        insane: "Insane",
        select_scan_strength: "Select Strength",
        select_scanner_alert_threshold: "Select Threshold",
        no_auth: "Non autenticato",
        form_auth: "Form based authentication",
        select_auth: "Seleziona il tipo di autenticazione",
        login_url: "URL pagina di login",
        login_parameter_name: "Nome del parametro per il 'nome utente'",
        password_parameter_name: "Nome del parametro per la 'password'",
        username: "Nome utente",
        password: "Password",
        logged_in_indicator: "Indicatore 'logged in'",
        logged_out_indicator: "Indicatore 'logged out'",
        regex: "Regex",
        scanned_urls: "URL Scansionati",
        authenticated: "Scansione autenticata"
      },
      new_cti_test: {
        note: `(a) Dichiari e garantisci di avere legittimazione, e/o pieno diritto e/o autorizzazione e/o autorità e/o delega 
              a condurre l’attività sui domini di analisi, utilizzando diverse tecniche (ad esempio: SIGINT, OSINT, HUMINT e successive analisi) 
              allo scopo di caratterizzare le possibili minacce informatiche.<br><br>
              (b) Le informazioni fornite dalla nostra piattaforma di Cyber Threat Intelligence sono fornite con la 
              modalità "visto e piaciuto, così come sono" e "come rese disponibili". Non garantiamo pertanto l'esattezza, 
              l'affidabilità o la completezza delle informazioni.<br><br>
              (c) Riconosci e accetti inoltre che il Servizio sottoscritto, le informazioni complessivamente raccolte e rese 
              disponibili sono strettamente confidenziali. Non dovrai divulgarli o utilizzarli in modo improprio. A tal riguardo, 
              Ti dichiari consapevole che la confidenzialità delle informazioni è una Tua responsabilità.<br><br>
              (d) Accetti di non utilizzare il nostro servizio per scopi illegali o proibiti dalle leggi del proprio paese o 
              dalla nostra politica di utilizzo accettabile.<br><br>
              (e) In nessun caso noi o i nostri fornitori saremo responsabili per qualsiasi danno, diretto o indiretto, incidentale, 
              speciale o conseguente, che derivi dall'uso improprio o dall'incapacità di utilizzare il nostro servizio o per la 
              violazione di qualsiasi diritto di terzi.<br><br>
              (f) Tinexta Cyber si riserva il diritto di modificare o interrompere il servizio in qualsiasi momento senza 
              preavviso in caso di uso improprio o non corretto da parte dell'utente.`
      },
      delete_related_reports: `Vuoi cancellare tutti i report relativi a questo target?<br /><br />
<strong>Attenzione:</strong> i test in stato pending e running non saranno eliminati. Dovrai eliminarli una volta terminati.`,
    },
    main: {
      last_ten_reports: `Ultimi 10 reports`,
    },
    report: {
      cve_details: `Dettagli CVE`,
      service: `Servizio | Servizi`,
      potential_gdpr: `GDPR Potenziali`,
      country_name: `Stato`,
      organization: `Organizzazione`,
      isp: `ISP`,
      hostname: `Hostname | Hostnames`,
      asn: `ASN`,
      cve: `CVE`,
      similarity: `Domain Similarity`,
      epss: `EPSS`,
      cvss: `CVSS`,
      cvssv2: `CVSSv2`,
      cvssv3: `CVSSv3`,
      impact: `Impatto`,
      severity: `Severità`,
      description: `Descrizione`,
      breaches: `Breach`,
      fullname: `Nome completo`,
      year: `Anno`,
      features: `Features`,
      total: `Totale`,
      protocol: `Protocollo`,
      product: `Prodotto`,
      version: `Versione`,
      subdomains: `Subdomains`,
      features_list: {
        "Browser user agent details": `Browser user agent details`,
        "Email addresses": "Email addresses",
        Genders: "Genders",
        "IP addresses": "IP addresses",
        "IP Addresses": "IP addresses",
        Names: "Names",
        Passwords: "Passwords",
        "Phone numbers": "Phone numbers",
        "Spoken languages": "Spoken languages",
        "Time zones": "Time zones",
        "Website activity": "Website activity",
        "Dates of birth": "Dates of birth",
        "Physical addresses": "Physical addresses",
        "Geographic locations": "Geographic locations",
        "Private messages": "Private messages",
        Usernames: "Usernames",
        Purchases: "Purchases",
        "Bank account numbers": "Bank account numbers",
        "Customer feedback": "Customer feedback",
        "Financial transactions": "Financial transactions",
        "Government issued IDs": "Government issued IDs",
        "Marital statuses": "Marital statuses",
        PINs: "PINs",
        "Security questions and answers": "Security questions and answers",
        "Social media profiles": "Social media profiles",
        "Credit card CVV": "Credit card CVV",
        "Partial credit card data": "Partial credit card data",
        "Biometric data": "Biometric data",
        "Family members names": "Family members names",
        "Job titles": "Job titles",
        "Passport numbers": "Passport numbers",
        "Physical attributes": "Physical attributes",
        "Beauty ratings": "Beauty ratings",
        "Car ownership statuses": "Car ownership statuses",
        "Drinking habits": "Drinking habits",
        "Education levels": "Education levels",
        "Home ownership statuses": "Home ownership statuses",
        "Income levels": "Income levels",
        "Personal descriptions": "Personal descriptions",
        "Personal interests": "Personal interests",
        "Sexual orientations": "Sexual orientations",
        "Smoking habits": "Smoking habits",
        "Recovery email addresses": "Recovery email addresses",
        Employers: "Employers",
        "Social connections": "Social connections",
        "Instant messenger identities": "Instant messenger identities",
        Ethnicities: "Ethnicities",
        "Credit status information": "Credit status information",
        "Historical passwords": "Historical passwords",
        "Auth tokens": "Auth tokens",
        "Device information": "Device information",
        Salutations: "Salutations",
        "Email messages": "Email messages",
        Bios: "Bios",
        "Homepage URLs": "Homepage URLs",
        "Credit cards": "Credit cards",
        "Support tickets": "Support tickets",
        "Sexual fetishes": "Sexual fetishes",
        "Astrological signs": "Astrological signs",
        "Drug habits": "Drug habits",
        "Fitness levels": "Fitness levels",
        "Parenting plans": "Parenting plans",
        "Political views": "Political views",
        "Relationship statuses": "Relationship statuses",
        Religions: "Religions",
        "Travel habits": "Travel habits",
        "Work habits": "Work habits",
        Avatars: "Avatars",
        "Email Addresses": "Email Addresses",
        "Physical Addresses": "Physical Addresses",
        "Payment histories": "Payment histories",
        "Reward program balances": "Reward program balances",
        "Account balances": "Account balances",
        "Birth dates": "Birth dates",
        "User website URLs": "User website URLs",
        "Health insurance information": "Health insurance information",
        "Personal health data": "Personal health data",
        "Deceased statuses": "Deceased statuses",
        Nationalities: "Nationalities",
        "Family structure": "Family structure",
        "Financial investments": "Financial investments",
        "Net worths": "Net worths",
        Occupations: "Occupations",
        "Device usage tracking data": "Device usage tracking data",
        "Cryptocurrency wallet hashes": "Cryptocurrency wallet hashes",
        "Mnemonic phrases": "Mnemonic phrases",
        "Geographic Location": "Geographic Location",
        "School grades (class levels)": "School grades (class levels)",
        "Address book contacts": "Address book contacts",
        "Apps installed on devices": "Apps installed on devices",
        "Cellular network names": "Cellular network names",
        "IMEI numbers": "IMEI numbers",
        "IMSI numbers": "IMSI numbers",
        "Profile photos": "Profile photos",
        "SMS messages": "SMS messages",
        "Professional skills": "Professional skills",
        "Years of professional experience": "Years of professional experience",
        "Customer interactions": "Customer interactions",
        "MAC addresses": "MAC addresses",
        "Purchasing habits": "Purchasing habits",
        Ages: "Ages",
        "Taxation records": "Taxation records",
        "Chat logs": "Chat logs",
        "User statuses": "User statuses",
        "Survey results": "Survey results",
        "Age groups": "Age groups",
        "Payment methods": "Payment methods",
        "Geographical Locations": "Geographical Locations",
        "Company titles": "Company titles",
        "Family data": "Family data",
        "Social security numbers": "Social security numbers",
        "Employment statuses": "Employment statuses",
        "Career levels": "Career levels",
        "Buying preferences": "Buying preferences",
        "Charitable donations": "Charitable donations",
        "Political donations": "Political donations",
        "Eating habits": "Eating habits",
        "Years of birth": "Years of birth",
        Races: "Races",
        "Job applications": "Job applications",
        "Vehicle details": "Vehicle details",
        "Home loan information": "Home loan information",
        "Password hints": "Password hints",
        "Deceased date": "Deceased date",
        "Utility bills": "Utility bills",
        Nicknames: "Nicknames",
        "Audio recordings": "Audio recordings",
        "Browsing histories": "Browsing histories",
        Photos: "Photos",
        "Personal information": "Personal information",
        "Hashed Passwords": "Hashed Passwords",
        "Document Titles": "Document Titles",
      },
      total_ip_found: `IP Totali trovati`,
      total_subdomains_found: `Sottodomini Totali trovati`,
      chart_full_impact: `Impatto su Confidentiality, Integrity e Availability`,
      chart_potential_by_risk: `Potenziali vulnerabilità per rischio`,
      chart_average_potential_vulnerabilities: `Media Potenziali Vulnerabilità per Sottodominio`,
      average_total: `Media Totali`,
      average_high: `Media Alte`,
      average_medium: `Media Medie`,
      average_low: `Media Basse`,
      typosquatting: `Typosquatting`,
      domain: `Domain`,
      data: `Data`,
      dns_a: `DNS A`,
      dns_mx: `DNS MX`,
      dns_ns: `DNS NS`,
      open_ports: `Porte aperte`,
      os: `O.S.`,
      key: `Chiave`,
      value: `Valore`,
      host: `Host | Hosts`,
      host_list: `Lista host`,
      vulnerabilities_risk_distribution: `Distribuzione vulnerabilità per gravità`,
      vulnerabilities_cia_impact: `Distribuzione vulnerabilità per impatto GDPR`,
      family: `Famiglia`,
      summary: `Sommario`,
      access_vector: `Access Vector`,
      access_complexity: `Difficoltà di accesso`,
      authentication: `Autenticazione`,
      solution: `Soluzione`,
      local: `Locale`,
      adjacent_network: `Network adiacente`,
      network: `Network`,
      multiple: `Multipla`,
      single: `Singola`,
      none: `Nessuna`,
      cia_impact: `Distribuzione vulnerabilità per impatto GDPR e relativa gravità`,
      average_vulnerabilities_per_host: `Media vulnerabilità per host`,
      creation_date: `Data di creazione`,
      breaches_chart: `Grafico Breaches`,
      breaches_by_risk: `Breaches per rischio`,
      upgrade_to_full: `Per vedere il report completo esegui l'upgrade della tua licenza e lancia una nuova scansione!`,
      port_status: {
        title: `Stato`,
        open: `Aperta`,
        closed: `Chiusa`,
      },
      top_vulnerable_host: `Host più vulnerabili`,
      vulnerabilities_trend: `Andamento vulnerabilità`,
      executive_report_ppt: "Executive Report PPT",
      remediation_plan_eng: "Remediation Plan ENG",
      remediation_plan_ita: "Remediation Plan ITA",
      full_report: "Full Report",
      dark_web_threat_history: "Dark Web Threat History",
      domain_name: "Nome dominio",
      name_server: "Name server",
      mail_server: "Mail server",
      cti: {
        data_breaches: "Breach dei Dati",
        comprimised_credentials_email: "Credenziali ed e-mail compromesse",
        data_breaches_description: `Rilevata breach dei dati (diretta e/o di terze parti) ed e-mail compromesse. A seconda dei casi è possibile fornire: Password utilizzata, Password Hash, Record senza password, ma di cui si ha traccia nel Deep e Dark Web`,
        breach_risks: `Severity delle Breach`,
        breaches_message: `Permette di rilevare la presenza di Data Breach - diretti o di terze parti - e di eventuali account compromessi.<br>
                È possibile visualizzare informazioni complete sull’account violato, impatto del breach, data e origine del breach.`,
        breaches_chart_message: `Questo grafico rappresenta la severità dei Data Breach rilevati per il Target <strong>{domain}</strong>. 
                La severity è basata sulla sensibilità dei dati esfiltrati e sulla data di pubblicazione del Breach.`,
        ip_threat_intelligence: "Intelligence sulle minacce IP",
        ip_threat_intelligence_sub: "Attività dannosa identificata sul tuo IP",
        ip_threat_intelligence_description: `La "reputazione" di un indirizzo IP pubblico è simile alla sua "cronologia di rete", ovvero la cronologia delle azioni dannose che sono state eseguite, o che sono passate, o che hanno avuto come destinazione finale, chiamata indirizzo IP. Responsabilità civile e penale, furto di informazioni, spionaggio industriale, ecc.`,
        dark_web: "Dark Web",
        dark_web_sub: "Cosa è nascosto all'occhio di Google",
        dark_web_description: `Analisi di istanze sul cosiddetto Dark Web, come gli attori delle minacce (in genere criminali informatici) sui forum di criminalità informatica che hanno parlato del <strong>{domain}</strong>. Questo livello di raccolta delle informazioni può migliorare la sicurezza del dominio, la sicurezza IP e la reputazione del marchio. Un altro aspetto è anche un Focus sulla diffusione di dati riservati o personali, truffe e frodi da perpetrare contro <strong>{domain}</strong>`,
        dark_web_message: `Permette di ottenere una visione del livello di attenzione da parte, tipicamente, di 
                Threat Actor rispetto al dominio oggetto di analisi.<br>
                È possibile individuare gli attori delle minacce che hanno citato o discusso dell'Organizzazione 
                (inteso come marchi, domini, indirizzi IP o nomi di esponenti apicali dell’azienda) sui forum di cyber crime`,
        botnet: "Attività Botnet",
        botnet_sub: "Dati esfiltrati da computer compromessi e appartenenti a Botnet",
        botnet_description: `Rileviamo l'attività delle botnet sulla tua rete. Una botnet è una rete di computer infettati da malware, spyware, keylogger, ecc. Il "bot hardener" (il botnet manager) può utilizzare la sua rete botnet per lanciare attacchi (ad esempio DDoS) o istruirlo a rubare credenziali (e- bancario, intranet aziendale, responsabilità civile e penale, furto di informazioni, spionaggio industriale, ecc.)`,
        certificate_message: `Elenca tutti i certificati SSL attualmente emessi per il dominio oggetto di analisi.<br>
                È possibile monitorare, e conseguentemente gestire in modo proattivo, i certificati 
                SSL associati ai propri domini; si potrà così garantire una corretta configurazione, 
                rilevando eventuali anomalie o utilizzi impropri / non autorizzati dei certificati e mitigando i 
                rischi derivanti dalle minacce legate ai certificati SSL.`,
        ip_address_threats: `Minacce agli indirizzi IP`,
        ip_address_threats_message: `Questi risultati rappresentano le <strong>eventuali minacce identificate da uno dei sensori pubblici o dalle comunità di sicurezza informatica</strong>. 
                Raccogliere tutti i dati da centinaia di fonti è piuttosto difficile e forniamo un sistema 
                intelligente per aggiornare e monitorare qualsiasi minaccia su IP specifici relativi al dominio <strong>{domain}</strong>.`,
        ip_history_data: `Dati cronologia IP`,
        ip_history_data_message: `La cronologia dell’IP deriva da attività OSINT e di Information Gathering infrastrutturale. Vengono fornite le evidenze raccolte fino agli ultimi 10 anni.`,
        ip_malicious_data: `Dati malevoli IP`,
        ip_malicious_data_message: `Elenco dei dati malevoli dell'IP selezionato.`,
        botnets_title: `Botnet`,
        botnets_message: `Questi dati rappresentano gli asset (computer, server, dispositivi IoT, smartphone) compromessi da botnet. 
                Una botnet è una rete di dispositivi infettati da malware, spyware, keylogger, ecc. 
                Vengono mostrati asset direttamente riconducibili all’Organizzazione, ma anche dispositivi personali o pubblici, 
                utilizzati per accedere a sistemi aziendali in modalità di lavoro remoto o comunque in perimetri esterni all’Azienda.`,
        attack_surface_title: `Superficie di attacco`,
        attack_surface_message: `La Superficie di Attacco è la combinazione di tutte le vulnerabilità, interfacce e accessi che possono essere utilizzati da un attaccante per violare la sicurezza di un sistema o di una rete.
    La superficie d'attacco di una determinata organizzazione o sistema comprende tutte le porte aperte, protocolli in uso, software installato e dispositivi connessi.`,
        risks_by_categories: "Rischi per Categorie",
        certificate_transparency: "Trasparenza del Certificato",
        certificates: "Certificati",
        malwares: "Malware IoC",
        malwares_message: "Sotto sono elencati gli ultimi IoC attivi relativi alle principali famiglie di malware rilevate nella sezione botnet",
        registered_phishing: `Phishing`,
        registered_phishing_sub: `Conteggio di domini registrati ottenuti per typosquatting utilizzabili per phishing`,
        registered_phishing_description: `Sono elencati i domini typosquatting registrati che potrebbero essere utilizzati al fine di ingannare gli utenti finali ed ottenere informazioni sensibili o condurre attività di phishing. Il typosquatting è una tattica di social engineering che sfrutta gli errori di digitazione nell'inserimento di un URL nel browser, al fine di reindirizzare l'utente verso un sito diverso da quello legittimo.`,
        unregistered_phishing: `Phishing Potenziale`,
        unregistered_phishing_sub: `Conteggio di domini non registrati ottenuti per typosquatting utilizzabili in futuro per phishing`,
        unregistered_phishing_description: `Sono elencati i domini typosquatting non ancora registrati che potrebbero essere utilizzati al fine di ingannare gli utenti finali ed ottenere informazioni sensibili o condurre attività di phishing. Il typosquatting è una tattica di social engineering che sfrutta gli errori di digitazione nell'inserimento di un URL nel browser, al fine di reindirizzare l'utente verso un sito diverso da quello legittimo.`,
        services: `Servizi`,
        services_sub: `La Superficie di Attacco esposta ai potenziali attaccanti viene identificata attraverso i Servizi Esposti.`,
        phishing_message: `Sono elencati i possibili domini typosquatting registrati che potrebbero essere utilizzati al fine di 
                  ingannare gli utenti finali ed ottenere informazioni sensibili o condurre attività di phishing. 
                  Il typosquatting è una tattica di social engineering che sfrutta gli errori di digitazione nell'inserimento 
                  di un URL nel browser.`,
      },
      delete_question: `Sei sicuro di voler cancellare questo report?`,
      stop_question: `Sei sicuro di voler fermare questo task?`,
      references: "Referenze",
      ip_down_list: `Lista IP Down`,
      hosts_down_list: `Lista Hosts Down`,
      webapp: {
        risk_chart: `Risk of Exploitation Based on Severity (%)`,
        vulnerabilities_chart: `Vulnerabilities Impact on Confidentiality, Integrity, Availability`,
        gdpr_chart: `GDPR Impact Distribution (%)`,
        likelihood_chart: `Likelihood of Exploitation Based on Severity`,
        web_server_information: `Informazioni Web Server`,
        ssl_security_tests: `Test di sicurezza SSL`,
        alerts_title: `Report Vulnerabilità`,
      }
    },
    new_target: {
      new_dti_target: `Aggiungi un nuovo Domain Threat Intelligence`,
      edit_dti_target: `Modifica target DTI`,
      dti_description: `Il servizio Domain Threat Intelligence consente di identificare la superficie di attacco
                potenziale del dominio aziendale. L’attività si svolge attraverso un processo di ricerca,
                identificazione e selezione delle informazioni disponibili al pubblico relative a Dominio, Sottodominio e
                Email compromesse. Il servizio non esegue alcun test di sicurezza sul target e funziona solo
                sulle informazioni disponibili sul web o sul dark web. Sulla base delle informazioni raccolte da fonti OSINT
                (Open Source Intelligence) e CLOSINT (Close Source Intelligence) vengono identificate
                le possibili vulnerabilità del target.`,
      dti: `L'attività di Threat Intelligence viene svolta su target e identificativi relativi alle risorse compromesse
                ed e-mail. Identifichiamo qualsiasi informazione pubblica disponibile relativa a un determinato target.`,
      new_nscan_target: `Aggiungi un nuovo Network Scan target`,
      edit_nscan_target: `Modifica Network Scan target`,
      nscan: `Il servizio di Network Scan consente la scansione di infrastrutture e dispositivi
                 per identificare le vulnerabilità e i problemi critici di sicurezza.`,
      webapp: `Il servizio di WebApp Scan è il servizio automatizzato che esegue la scansione delle vulnerabilità Web;
	             questo servizio identifica le vulnerabilità e le criticità della sicurezza di siti e applicazioni web.`,
      business_categories: "Categoria Business",
      target: "Target",
      extra: "Extra",
      vpn_optional: "Facoltativo",
      vpn_recommended: "Consigliato",
      vpn_not_recommended: "Sconsigliato",
      vpn_settings: "Impostazioni VPN ({suggestion})",
      vpn_description: `Se il tuo server è collegato tramite VPN puoi utilizzare queste impostazioni (la
                VPN consente di inviare e ricevere dati su reti condivise o pubbliche, come se i loro dispositivi
                informatici fossero collegati direttamente alla rete privata).`,
      has_vpn: `<strong>NOTA:</strong> Hai già configurato una VPN in precedenza`,
      vpn_with_public: `Hai inserito una configurazione VPN con degli indirizzi pubblici. Sei sicuro di voler procedere?`,
      private_no_vpnc: `Hai inserito degli indirizzi privati senza una configurazione VPN. Sei sicuro di voler procedere?`,
      open_vpn: "OpenVPN OVPN",
      net_extender: "Sonicwall Net Extender",
      select_vpn: "Seleziona la VPN",
      no_auth: "No VPN",
      user: "User",
      address: "Address",
      domain: "Domain",
      password: "Password",
      ovpn_file: "OVPN File",
      choose_file: "Scegli File",
      cancel_file: "Elimina File",
      open_vpn_description: `OpenVPN è un'applicazione software open source che implementa una Rete Privata Virtuale (VPN)
                per la creazione di connessioni sicure point-to-point o site-to-site ruoted o bridged
                e accesso a strutture remote. Utilizza uno specifico protocollo di sicurezza che
                utilizza SSL/TLS per lo scambio di chiavi. È in grado di attraversare NAT
                e firewall. Abbiamo creato un'appliance per testare facilmente gli IP in una LAN e puoi leggere la nostra
                documentazione per la configurazione al link (<a href='https://www.swascan.com/it/tutorial-vpn/' target='_blank'>www.swascan.com/it/tutorial-vpn/</a >), ma prima devi scaricare questo pacchetto:`,
      virtual_appliance: "Virtual Appliance",
      windows_vmware_player: "VmWare Player per Windows",
      open_von_confg_files: "File di Configurazione OpenVpn",
      sonic_wall_description: ` SonicWall dispone di funzionalità SSL VPN native integrate. Puoi abilitare la Piattaforma Tinexta Cyber
                e analizzare i dispositivi nella tua LAN facilmente e in pochi passaggi.
                Primo passo: devi configura il tuo firewall per accettare connessioni VPN e puoi trovare una semplice guida
                navigazione nel sito Web di Sonicwall (https://www.sonicwall.com/en-us/support/knowledge-base/170505401898786)
                Secondo passo: è necessario inserire le informazioni su:`,
      sonic_wall_user: "Username dell'utente VPN",
      sonic_wall_password: "Password dell'utente VPN",
      sonic_wall_domain: "Dominio",
      sonic_wall_address: "Indirizzo Server e porta (per esempio: xxx.xxx.xxx.xxx:4433)",
      sonic_wall_tunneling: "L'unico requisito: non configurare il server VPN per reindirizzare l'intero traffico nel tunnel.",
      sonic_wall_logo: "Logo di Sonic Wall",
      open_vpn_config_file: "File di configurazione per Open VPN:",
      only_ovpn_file: "* Solo file .ovpn",
      open_vpn_credentials_file: "File delle credenziali per Open VPN (facoltativo):",
      only_txt_file: "* Solo file .txt",
      ip_address_not_valid: `IP, subnet, IP range o nome host non valido`,
      ip_examples: `Examples: www.my-site.com, 192.168.1.1, 125.168.100.0/24, 10.0.0.1-10.0.0.15`,
      some_examples: "Esempi:",
      insert_ip_range: {
        text: "Per inserire un range di indirizzi IP:",
        first_ip: "digita il primo indirizzo IP,",
        middle_hyphen: "digita il carattere - (trattino medio) per separare i due IP,",
        second_ip: "digita il secondo indirizzo IP.",
        example_1: "10.0.0.1-10.0.0.15",
        example_2: "10.0.0.1-10.0.0.180",
      },
      insert_ip_with_subnet_mask: {
        text: "Per inserire un indirizzo IP con Subnet Mask:",
        ip: "digita l'indirizzo IP,",
        slash: "digita il carattere / (slash),",
        subnet_mask: "digita la subnet mask (da 20 a 32).",
        example_1: "125.168.100.0/24",
        example_2: "192.168.0.10/28",
      },
      unchanged: "Invariato",
      new_cti_target: "Aggiungi un nuovo Cyber Threat Intelligence",
      edit_cti_target: "Modifica target CTI",
      cti_description: `L'attività di Cyber Threat Intelligence (CTI) si concentra principalmente sull’analisi di dati “grezzi” raccolti durante eventi specifici - recenti e passati - per monitorare, rilevare e prevenire minacce di Cyber Security ad un’organizzazione. Lo fa spostando l’attenzione dalla difesa reattiva alle misure di sicurezza preventive e “intelligenti”. Idealmente, la CTI dovrebbe diventare la base su cui un’azienda costruisce il proprio perimetro di difesa sicuro, vigile e resiliente.`,
      cti: `L'attività di Cyber Threat Intelligence (CTI) si concentra principalmente sull'analisi di dati "grezzi" raccolti durante specifici eventi - recenti e passati.`,
      tvm: `Il Technology Vulnerability Monitoring monitora le fonti di threat intelligence, il flusso MITRE, notizie e blog di settore per intercettare falle e criticità rilevanti per il contesto tecnologico selezionato, fornendo supporto allo staff IT per valutare il livello di esposizione al rischio informatico e impostare piani di mitigazione.`,
      dti_lite: `L’attività di Threat Intelligence viene eseguita su target relativi agli asset compromessi ed email. Identifichiamo ogni informazioni pubblicamente disponibile relativa ad un dato target, ma viene mostrata solo una Sintesi.`,
      extra_targets: `Extra target (IP)`,
      new_webscan_target: `Aggiungi un nuovo WebApp Scan`,
      edit_webscan_target: `Modifica target WebApp Scan`,
      rri: `Il servizio restituisce una panoramica del livello di rischio di attacco tramite ransomware. 
            Questo avviene tramite l’analisi delle potenziali aree vulnerabili del perimetro aziendale che potrebbero essere attaccate dai 
            Criminal Hacker.<br>
            Il servizio - effettuato a cadenza regolare - fornisce un importante indicatore di sicurezza proattiva e un driver per successive 
            attività di security testing.`,
      new_rri_target: `Aggiungi un nuovo Ransomware Risk Indicator`,
      edit_rri_target: `Modifica target Ransomware Risk Indicator`,
    },
    search: {
      category: `Categoria`,
      thread_name: `Thread name`,
      posts: `Posts`,
      author: "Autore",
    },
    phishing_attack: {
      phishing_attack: "Phishing Attack Simulation",
      phishing_attack_description: `Il servizio di Phishing Attack Simulation ti permette di 
                impostare una efficace attività di formazione e awareness per tutto il tuo staff.
                Questa soluzione innovativa garantisce, attraverso simulazioni di attacchi reali,
                prevenzione in caso di attacco phishing.`,
      why_simulation: "Perché un Phishing Attack Simulation?",
      reduce_risks: "Riduce il rischio di un attacco Phishing di Successo",
      reduce_costs: "Riduce i costi di formazione sulla Sicurezza Cyber",
      identify_phishing: "Educa i dipendenti ad identificare attacchi Phishing ",
      compliance_standards: "Soddisfa gli standard di Compliance",
    },
    incident_response: {
      cyber_security_expert: "Un Esperto di Sicurezza Cyber a tua disposizione!",
      incident_management: ` Il Servizio Cyber Emergency Response di Tinexta Cyber supporta i clienti nelle attività di gestione degli incidenti IT`,
      incident_management_note: `Il Servizio Tinexta Cyber di Cyber Incident Response prevede 1 chiamata per licenza con una consultazione telefonica di massimo 1 ora`,
      data_breach: "Hai subito un Data Breach?",
      data_breach_description: `I tuoi dati sono stati criptati da un virus ransomware? Oppure hai subito una breach dei dati? Il servizio Incident Response di Tinexta Cyber ti fornirà le indicazioni e i passi da attuare per ripristinare la Continuità Operativa aziendale`,
      expert_available: " Un esperto a tua disposizione ",
      expert_available_description: `Un esperto di Cyber Security a tua disposizione per supportarti, in caso di Data Breach, nella gestione dell'Incident Response Management. Un team di professionisti Cyber a tua disposizione: `,
      h24: "H24",
      reporting: " Entro 4 ore dalla segnalazione ",
      consulting: "1 ora di consulenza",
      service_name: "Cyber Incident Response Service",
      privacy: `Il sottoscritto, in qualità di interessato DICHIARA di aver letto e compreso il contenuto 
                della <a href="https://www.swascan.com/it/informativa-sotto-attacco/" target="_blank">privacy policy</a> ai sensi 
                dell’articolo 13, GDPR. ACCONSENTE al trattamento dei Dati in relazione all’invio da parte del Titolare di comunicazioni 
                afferenti alla gestione di eventuali misure precontrattuali, preordinate alla stipulazione e/o esecuzione del contratto con 
                il Cliente nonché all'adempimento dei relativi obblighi.<br>
                Il consenso prestato potrà essere revocato in qualsiasi momento contattando il Titolare ai recapiti presenti nella citata 
                privacy policy.`,
      form: {
        task_name: `Titolo`,
        task_error: `* Titolo obbligatorio`,
        name_error: "* Nome obbligatorio",
        surname_error: "* Cognome obbligatorio",
        url_error: "* URL obbligatorio",
        company_error: "* Azienda obbligatoria",
        email_error: "* Email obbligatoria, formato errato",
        incident_type_error: "* Nome obbligatorio",
        description_error: "* Descrizione obbligatoria",
        phone_error: "* Telefono obbligatorio",
      },
    },
    smishing_attack: {
      smishing_attack: "Smishing Attack Simulation",
      smishing_attack_description: `Il servizio di Smishing Attack Simulation è una
                parte fondamentale di qualsiasi programma di formazione sulla sicurezza
                informatica. Grazie alla sua avanzata capacità di simulazione, sarà possibile
                eseguire riproduzioni reali di attacchi Smishing  - punto cruciale per la
                consapevolezza dei dipendenti sulle minacce informatiche.`,
      why_choose: "Perché scegliere il servizio di Smishing Attack Simulation?",
      reduce_risks: " Riduce il rischio di un attacco Smishing di Successo ",
      identify_attacks: "Permette ai dipendenti di individuare e identificare attacchi Smishing ",
      reduce_cost: " Riduce i costi di formazione sulla Sicurezza Cyber ",
      compliance_standards: " Soddisfa gli standard di Compliance ",
    },
    cyber_threat: {
      cyber_threat: "Cyber Threat Intelligence",
      cyber_threat_description: ` Il servizio di Cyber Threat Intelligence ti fornisce
                informazioni preziose sul livello di rischio a cui è esposta la tua azienda.
                Questo servizio di allerta e di sicurezza informatica preventiva consta nella
                raccolta e analisi delle informazioni al fine di delineare possibili minacce
                informatiche, da un punto di vista tecnico, in relazione a specifici contesti
                operativi. Il servizio di Cyber Threat Intelligence di Tinexta Cyber ha lo scopo e
                obiettivo di identificare qualsiasi informazione pubblica disponibile, relativa
                ad uno specifico target, a livello OSINT e CLOSINT.`,
      thanks_cti: "Grazie al CTI puoi scoprire:",
      data_breach: "Data Breach & email compromesse",
      network_hygiene: "Minacce relative all’Igiene di Rete",
      dark_web: "attività di Dark Web ",
      bot_net: "Attività Botnet &amp; Rischi Vari",
      account_type: `Your account type is<br><strong>{type}</strong><br>Subscription expire date:<br><strong>{date}</strong>`,
      scan_limit: `Scan target limit<br><strong>{limit}</strong>`,
      available_scans: `Available Scans<br><strong>{value}</strong>`,
      report_generated: `Report generated<br><strong>{value}</strong`,
    },
    penetration_test: {
      penetration_test: "Penetration Test",
      penetration_test_description: `Con Penetration Test si intende un attacco autorizzato
                (simulato) su un sistema informatico aziendale per testare la sicurezza dello stesso.
                Il Penetration testing rappresenta la best practice per scovare i punti deboli della
                propria azienda dal punto di vista della Cyber Security. Lo scopo è di evidenziare le
                eventuali vulnerabilità e problematiche di sicurezza presenti, analizzandole tramite
                i risultati forniti sotto forma di reportistica, e di indicare contromisure di tipo
                tecnologico, organizzativo e procedurale in grado di innalzare lo stato complessivo
                della sicurezza aziendale.`,
      attack_simulation: "Simulazione di un attacco informatico",
      activity_executions: "Esecuzione delle attività sia in modalità Black Box che White Box",
      critical_issues: "Classificazione delle vulnerabilità in base alle criticità",
      pdf_reports: "Erogazione di una reportistica finale in formato PDF",
    },
    security_academy: {
      security_academy: "Cyber Academy",
      security_academy_description: `Per le aziende di tutti i settori la Sicurezza Informatica
                è un tema sempre più sentito che garantisce la protezione dei sistemi informatici aziendali
                contro ingressi non autorizzati e sottrazione di dati. La Cyber Academy di Tinexta Cyber
                fornisce corsi specializzati e tailor made a tutto il personale aziendale, formandoli
                nei diversi ambiti della sicurezza informatica. I corsi sono gestiti da professionisti
                riconosciuti nel settore. I corsi vengono erogati in base all’esigenza e alle necessità
                con l’obiettivo di essere in linea con il contesto tecnologico aziendale.`,
      packets: "Erogazione di pacchetti da 8 ore",
      training: "Formazione professionale dei dipendenti aziendali",
      certificate: "Erogazione di un attestato di partecipazione",
      courses: "Gestione dei corsi tramite professionisti del settore",
    },
    cyber_incident_team: {
      cyber_incident_team: "Cyber Incident Team",
      cyber_incident_team_description: `Tinexta Cyber mette a disposizione delle aziende il suo servizio di
                Incident Response tramite un Team altamente specializzato e dedicato di pronto intervento
                Cyber per la gestione di Cyber Incident, attacchi DDOS, Data Breach e attacchi Ransomware.
                Il Team è composto da professionisti esperti con una lunga esperienza in ambito CSIRT. Il
                Cyber Incident Response Team di Tinexta Cyber ha identificato e gestito per primo diversi Ransomware
                di nuova generazione oltre ad aver ottenuto riconoscimenti ufficiali da player internazionali
                per le attività di Vulnerability Disclosure.`,
      critical_issues: "Identificazione di vulnerabilità e criticità",
      consulting: "Consulenza professionale di 1 ora",
      availability: "Disponibilità H24, 7 giorni su 7",
      first_aid: "Pronto intervento entro 4 ore dalla segnalazione",
    },
    soc_as_service: {
      soc_as_service: "Soc as a Service",
      soc_as_service_description: `Un Security Operations Center è un centro di competenza Cyber che
                eroga servizi finalizzati alla governance, monitoring e management della sicurezza dei sistemi
                informativi aziendali. La sua progettazione, messa in esercizio e mantenimento può essere costoso
                e complesso. Il servizio SOC as a Service di Tinexta Cyber è la soluzione più efficace, efficiente,
                coerente e sostenibile per i contesti aziendali. Con il suo servizio di Monitoring & Early
                Warning permette di identificare, rilevare, analizzare e segnalare gli attacchi informatici
                cyber prima che possano trasformarsi in una minaccia concreta per l’azienda.`,
      availability: "Disponibilità H24, 7 giorni su 7, del Team dei Security Analyst",
      reporting: "Reportistica mensile sulle attività svolte",
      activation: "Servizio attivo e operativo in meno di 48 ore",
      subscription_service: "Servizio ad abbonamento",
    },
    code_review: {
      title: `Code Review`,
      title2: `Cosa ottieni grazie al servizio Code Review:`,
      description: `Code Review è il servizio di analisi del codice sorgente di un'applicazione per verificare che i requisiti minimi di sicurezza necessari siano presenti ed efficaci. La verifica del codice è lo strumento per assicurarsi che l'applicazione sia stata sviluppata in modo da "auto-proteggersi" nel suo ambiente. Individua e corregge gli errori sfuggiti agli sviluppatori in fase di sviluppo, migliora la qualità complessiva del software e anche le abilità dello sviluppatore stesso.`,
      bullets: {
        bullet1: `Analisi dettagliata del codice sorgente`,
        bullet2: `Identifica le vulnerabilità e le criticità`,
        bullet3: `Verifica la conformità con il GDPR`,
        bullet4: `Generazione automatica di un pdf e CSV`,
      },
    },
    osint_search: {
      title: `OSINT Search`,
      request_description: `Per attivare questo servizio contatta il nostro team`,
    },
    subdomains: "Sottodomini",
    vulnerability_early_warning: {
      title: "Technology Vulnerability Monitoring",
      activate_product: "Attiva notifiche",
      service_description: `Il servizio è concepito come una soluzione dedicata, ideale per la
                gestione proattiva del rischio relativo alle vulnerabilità tecnologiche. Il Technology 
                Vulnerability Monitoring monitora le fonti di threat intelligence, il flusso MITRE, notizie e blog
                di settore per intercettare falle e criticità rilevanti per il contesto tecnologico selezionato,
                fornendo supporto allo staff IT per valutare il livello di esposizione al rischio informatico e
                impostare piani di mitigazione.`,
      email_label: "Vorrei ricevere i risultati all'email:",
      select_vendors_and_products: "Seleziona venditori e prodotti",
      select_vendor: "Seleziona venditore:",
      select_product: "Seleziona prodotto:",
      add: "Aggiungi",
      no_products_selected: "Nessun prodotto selezionato",
      limit_reached: "Hai raggiunto il limite massimo di prodotti",
      vendor: "Venditore",
      product: "Prodotto",
      email_error: "* Email richiesta, o formato errato",
      saved: "Prodotti salvati",
      delete: "Elimina",
      product_warning: "Prodotto non presente",
      vendor_warning: "Venditore non presente",
      product_placeholder: "Seleziona un prodotto",
      vendor_placeholder: "Seleziona un venditore",
      no_filter: "Usa la ricerca per filtrare i vendor.",
      remove_all: "Rimuovi sottoscrizioni",
      remove_message: `L’interessato potrà in ogni tempo ed in piena autonomia esercitare il diritto di opt-out cancellando tutti i dati mediante la pressione del tasto di cancellazione ovvero cancellando le e-mail e/o i prodotti`,
    },
    tvm: {
      tvm: "Technology Vulnerability Monitoring",
      tvm_description:
        "Il servizio è concepito come una soluzione dedicata, ideale per la gestione proattiva del rischio relativo alle vulnerabilità tecnologiche. Il Technology Vulnerability Monitoring controlla le fonti di threat intelligence, il flusso MITRE, notizie e blog di settore per intercettare falle e criticità rilevanti per il contesto tecnologico selezionato, fornendo supporto allo staff IT per valutare il livello di esposizione al rischio informatico e impostare piani di mitigazione.",
      product_list: "Cosa ottieni grazie al servizio Technology Vulnerability Monitoring",
      icon_1: "Identifica le vulnerabilità e le criticità",
      icon_2: "Mantenimento proattivo della sicurezza",
      icon_3: "Alert tempestivi",
    },
    security_measures: {
      title: `Misure di Sicurezza`,
      description: `Tinexta Cyber al fine di rendere edotti i propri clienti in merito alla sicurezza dei propri dati personali trattati mediante la piattaforma proprietaria presente in cloud, dispone quanto segue:`,
      bullet_1: `L’organizzazione ha previsto all’interno del proprio organico specifiche figure professionali dedicate allo sviluppo sicuro della piattaforma ed al mantenimento delle misure di sicurezza relativamente all’ambiente cloud.`,
      bullet_2: `Tinexta Cyber non effettuerà alcuna divulgazione spontanea dei dati personali 
            concessi dai propri clienti. Qualora un’autorità statale richieda i Vostri dati personali, 
            Tinexta Cyber cercherà di indirizzare la richiesta da parte dell’autorità nei vostri confronti. 
            Si precisa che tutte le richieste inoltrate da un’Autorità a Tinexta Cyber in merito ai dati 
            personali dei propri clienti dovranno essere inviate nel rispetto delle Leggi e dei Regolamenti 
            in materia, in caso contrario Tinexta Cyber non divulgherà alcun contenuto. Infine, qualora un’Autorità 
            richieda e ottenga, in virtù di quanto precedentemente affermato, i dati personali, Tinexta Cyber si impegna ad 
            informare l’interessato dell'oggetto della richiesta al fine di consentire agli stessi di ottenere informazioni 
            presso dette Autorità e/o ottenere provvedimenti cautelari, qualora a Tinexta Cyber non venga impedito di farlo in 
            forza delle Leggi eo regolamenti applicabili. Al fine di monitorare tutte le richieste che provengono da 
            Autorità Tinexta Cyber detiene un apposito registro all’interno del quale sono presenti i dati trasmessi e l’Autorità richiedente ricevente.`,
      bullet_3: `I dati personali trattati relativamente all’esecuzione del servizio in cloud saranno conservati presso i data center di Amazon Web Services, locati a Francoforte in Germania, all’interno dello Spazio Economico Europeo.`,
      bullet_4: `I dati personali saranno conservati e successivamente cancellati secondo quanto previsto dal documenti PR_01 Data retention policy.`,
      bullet_5: `Tinexta Cyber offre ai propri partner e clienti una serie di strumenti attraverso i quali proteggere i propri dati e quelli dei clienti dai numerosi vettori di attacco presenti. Al fine di garantire un’adeguato livello di protezione delle informazioni e dei dati personali trattati nell’ambito delle proprie attività, la quale contempla controlli di sicurezza quali:`,
      bullet_5_a: `encryption dei dati memorizzati sugli storage del Cloud Provider;`,
      bullet_5_b: `encryption dei dati in transito;`,
      bullet_5_c: `WAF (Web Application Firewall) volto alla mitigazioni di attacchi (DDoS ed altri);`,
      bullet_5_d: `Verifiche periodiche indipendenti sulla sicurezza della piattaforma (VA / PT);`,
      bullet_5_e: `Scrupolosa attenzione a procedure volte a garantire la continuità di servizio.`,
      bullet_5_end: `Infine, mediante l’utilizzo dei servizi erogati tramite Amazon web Services, monitora l’uso degli accessi con l’ausilio di Aws Cloud Trail, si precisa che Tinexta Cyber non può ridurre le predette misure di sicurezze unilateralmente.`,
      bullet_6: `Tinexta Cyber provvede al backup periodico dei dati cliente (oltre a quelli necessari per il funzionamento dei propri servizi) almeno giornaliero mantenendo le copie degli ultimi 30 gg, a garanzia della continuità di servizio per la disponibilità di accesso dei dati. I dati sono mantenuti criptati, e l’accesso è riservato ad un sottoinsieme qualificato del personale tecnico Tinexta Cyber.`,
      footer: `Unitamente a questi controlli, Tinexta Cyber è certificata conforme allo standard ISO/IEC 27001, che garantisce sulle procedure indicate (ed altre) mirate alla sicurezza e riservatezza delle informazioni e dei dati dei clienti.`,
    },
    elearning_academy: {
      elearning_academy: "Cyber e-Learning Platform",
      elearning_academy_description: `L'obiettivo della Piattaforma e-Learning è agire sulla consapevolezza di ogni 
                    dipendente e sulla sua capacità di riconoscere minacce potenziali e conseguentemente adottare 
                    comportamenti corretti per rafforzare il livello di sicurezza delle organizzazioni.<br><br>
                    
                    I Corsi in modalità E-learning formano tutti gli attori aziendale, aggiornandoli sulle potenziali 
                    minacce cyber, sui comportamenti adeguati e proattivi da adottare al fine di evitare frodi informatiche, 
                    attacchi di phishing e molto altro.<br><br>
                    
                    Inoltre, i nostri Corsi permettono alle aziende di essere compliance con le attuali disposizioni 
                    legislative e la corretta gestione dei dati personali.`,
      bullet_1: "Materiale Formativo in continuo rilascio",
      bullet_2: "User Experience accattivante",
      bullet_3: "Didattica a distanza",
      bullet_4: "Linguaggio divulgativo e semplice",
      bullet_5: "Test di Valutazione finale",
      bullet_6: "Attestato di Partecipazione",
    },
    rri: {
      description: `Il servizio restituisce una panoramica del livello di rischio di attacco tramite ransomware. 
                    Questo avviene tramite l’analisi delle potenziali aree vulnerabili del perimetro aziendale che potrebbero 
                    essere attaccate dai Criminal Hacker.<br>
                    Il servizio - effettuato a cadenza regolare - fornisce un importante indicatore di sicurezza proattiva e un 
                    driver per successive attività di security testing.`,
      icon_1: `Analizza il rischio di attacchi Ransomware`,
      icon_2: `Mantenimento proattivo della sicurezza`,
      icon_3: `Indicatore di allerta semplice e chiaro`,
    },
    cloud_security: {
      title: "Cloud Services",
      description: `Un Cyber Competence Center dedicato al mondo Cloud per le attività di governance, 
                    supporto e tutela dell’intero insieme di tecnologie, protocolli e best practice 
                    degli ambienti cloud computing.`,
      benefits: `Cosa ottieni con il servizio Cloud Services`,
      icon_1: `CSA Framework Assessement`,
      icon_2: `Cloud Environment Security Assessment (CESA)`,
      icon_3: `SaaS Supply Chain Assessment`,
      icon_4: `Azure Active Directory Assessment (AADA)`,
    },
    webscan: {
      title: "WebApp Scan",
      description: `WebApp Scan è il servizio automatizzato di Web Vulnerability Scan. Il Tool di Vulnerability Assessment 
                    Scanner permette di identificare le vulnerabilità e le criticità di sicurezza di siti web e delle 
                    applicazioni Web. L’analisi delle vulnerabilità ha lo scopo di quantificare i livelli di rischio e indicare 
                    le azioni correttive e di riposizionamento necessarie per il ripristino.`,
      icon_1: `Segnalazione dei maggiori rischi`,
      icon_2: `Azioni correttive`,
      icon_3: `Indicazione dettagli`,
      icon_4: `Report scaricabile`,
      alerts_title: `Report Vulnerabilità`,
    },
    card_protection: {
      title: "Card Protection",
      description: `Ci dispiace, ma al momento non hai attivato il nostro servizio di "Card Protection". Questo servizio offre una 
              serie di vantaggi essenziali per la sicurezza delle tue carte di pagamento, tra cui notifiche immediate in caso di 
              violazioni (BReach) e un report mensile dettagliato sulle attività relative alle tue carte. Per attivare il servizio 
              e beneficiare di queste funzionalità di sicurezza avanzate, ti invitiamo a contattarci premendo il pulsante sotto.`,
      service_description: `Il servizio di Card Protection offre una soluzione efficace per la sicurezza delle tue carte di pagamento. 
              Con questo servizio, riceverai notifiche puntuali in caso di violazioni (Breach), oltre a un report mensile dettagliato. 
              Per attivare il servizio, tutto è sufficiente inserire il numero della tua carta di pagamento e scegliere un alias per 
              identificarla in modo univoco. È importante notare che il sistema CARD PROTECTION garantisce la massima sicurezza dei 
              tuoi dati: il numero della carta non verrà memorizzato in formato chiaro, ma verrà crittografato utilizzando l'algoritmo 
              SHA-256, garantendo così una protezione avanzata per le tue informazioni finanziarie.`,
      disclaimer: `Il numero di carta non è memorizzato in chiaro, ma è criptato con SHA-256;<br>saranno mantenute solo le ultime 4 cifre`,
      product_list: `Cosa ottieni grazie al servizio Card Protection:`,
      icon_1: "Mantenimento proattivo della sicurezza",
      icon_2: "Alert tempestivi",
      remove_all: `Cancella tutto`,
      email_label: "Vorrei ricevere i risultati all'email:",
      email_error: "* Email obbligatoria, formato errato",
      remove_message: `L’interessato potrà in ogni tempo ed in piena autonomia esercitare il diritto di opt-out cancellando tutti i dati mediante la pressione del tasto di cancellazione`,
      add_card: `Aggiungi una Carta di Credito`,
      cards_add: `Aggiungi nuova carta`,
      cards_del: `Rimuovi carta`,
      last_4_digits: `Numero di Carta`,
      card_number: `Numero carta`,
      alias: `Card Label`,
      no_cards: `Nessuna carta inserita. Inizia ad inserirne!`,
      card_added: `La carta è stata salvata con successo!`,
      card_removed: `La carta è stata rimossa con successo!`,
      recipients: `Aggiorna email`,
      recipients_updated: `Elenco Email aggiornato correttamente!`,
    },
  },
  menu: {
    dashboard: `Dashboard`,
    my_sites: `I miei siti`,
    dti: `Domain Threat Intelligence`,
    webapp: `Web App Scan`,
    nscan: `Network Scan`,
    code_review: `Code Review`,
    vulnerability_early_warning: "Technology Monitoring",
    gdpr: `GDPR Assessment`,
    ict: `ICT Assessment`,
    survey_gdpr: `GDPR Assessment`,
    survey_ict: `ICT Assessment`,
    survey_test: `TEST Assessment`,
    phishing_attack: `Phishing Attack`,
    incident_response: `Incident Response`,
    smishing_attack: `Smishing Attack`,
    cyber_threat: `Cyber Threat Intelligence`,
    available_licenses: `Servizi disponibili`,
    active_licenses: `Servizi acquistati`,
    main: `Dashboard`,
    targets_list: `Targets`,
    reports_list: `Reports`,
    threat_intelligence: "Threat Intelligence",
    technology_risk: "Technology Risk",
    penetration_test: "Penetration Test",
    human_risk: "Human Risk",
    security_academy: "Cyber Academy",
    risk_management: "Risk Management",
    data_breach_mgmt: "Data Breach Management",
    cyber_incident_team: "Cyber Incident Team",
    soc_as_service: "Soc As a Service",
    osint_search: `OSINT Search`,
    dti_lite: `Domain Threat (Lite)`,
    contact_us: `Apertura Segnalazione`,
    elearning_academy: "Cyber e-Learning Platform",
    rri: "Ransomware Risk Indicator",
    cloud: "Cloud",
    cloud_security: "Cloud Security",
    card_protection: "Card Protection",
  },
  footer: {
    terms_and_conditions: `Termini e condizioni`,
    terms_and_conditions_link: `https://www.swascan.com/it/termini-e-condizioni/`,
  },
  statuses: {
    SUCCESS: `Completato`,
    FAILURE: `Fallito`,
    RUNNING: `In corso`,
    PENDING: `In coda`,
    DELETED: `Cancellato`,
    STOPPED: `Fermato`,
    STOPPING: `In fase di stop`,
  },
  business_categories: {
    Agriculture: "Agriculture",
    "Basic Metal Production": "Basic Metal Production",
    "Chemical industries": "Chemical industries",
    Clothing: "Clothing",
    Commerce: "Commerce",
    Construction: "Construction",
    Culture: "Culture",
    Drink: "Drink",
    Education: "Education",
    "Financial services": "Financial services",
    Food: "Food",
    Footwear: "Footwear",
    Forestry: "Forestry",
    "Health services": "Health services",
    Hotels: "Hotels",
    "Mechanical and electrical engineering": "Mechanical and electrical engineering",
    Media: "Media",
    "Oil and gas production": "Oil and gas production",
    "Postal and telecommunications services": "Postal and telecommunications services",
    "Professional services": "Professional services",
    "Public service": "Public service",
    Shipping: "Shipping",
    Textiles: "Textiles",
    Tourism: "Tourism",
    Transport: "Transport",
    "Utilities (water; gas; electricity)": "Utilities (water; gas; electricity)",
  },
  errors: {
    no_remaining_scan: `<h4>Non hai più scansioni a disposizione.<br>Contattaci per effettuare l'upgrade!</h4>`,
    wrong_targets_format: `Target non valido`,
    ir_form_error: `Qualcosa è andato storto durante l'invio del form!`,
  },
  about: {
    system_title: "Versione applicativo",
    platform: "Piattaforma",
    engines: {
      dti: "DTI Engine",
      nscan: "Network Scan Engine",
      nmap: "Nmap Engine",
      vpn_manager: "VPN Manager",
      gvm: "GVM Engine",
      cti: "CTI Engine",
    },
  },
  login: {
    reset_password: "Reset Password",
    no_account: "Non hai un account?",
    get_started: "Registrati adesso. E' semplice veloce!",
    register: "Registrati",
    sign_in: "Accedi",
    credentials_error: "Credenziali errate",
  },
  breaches: {
    source: {
      actor_name: "Nome dell'attore",
      category: "Categoria",
      geography: "Geografia",
      attack_vector: "Vettore attacco",
      compromised_data: "Dati compromessi",
    },
  },
  contact_form: {
    subtitle: `Utilizza il modulo qui di seguito per richiedere assistenza in caso tu abbia riscontrato qualche anomalia ai servizi:`,
    title: `Categoria della segnalazione`,
    engine: `Seleziona il servizio per il quale hai riscontrato l’anomalia`,
    type: `Seleziona in quale delle due aree si è verificato il problema`,
    report_object: `Seleziona per quale target/ Report necessiti assistenza`,
    notes: `Descrivi brevemente la problematica riscontrata`,
    privacy: `II sottoscritto, in qualità di interessato DICHIARA di aver letto e compreso il contenuto della <a href="https://www.swascan.com/privacy-policy/" target="_blank">privacy policy</a> sul trattamento dei Dati ai sensi dell’articolo 13, GDPR ed espressamente presta il proprio consenso in relazione al trattamento dei propri dati personali per l’esecuzione del superiore servizio.`,
    send: `Invia segnalazione`,
    processing: `Invio in corso...`,
    ticket_success: `Invio richiesta avvenuta con successo!`,
    title_failed_scan: `Scansione Fallita`,
    title_report: `Report non scaricabile`,
    title_license: `Licenza non valida`,
    title_generic_error: `Errore Generico`,
    title_other: `Altro`,
  },
  ransomware_attack_index: {
    description: `Il Ransomware Attack Index indica il livello di rischio di attacco Ransomware in 
                  considerazione dei valori e dati raccolti e analizzati negli ultimi 30gg relativamente al dominio aziendale.<br><br>
                  Tale indice si basa su:`,
    bullet1: `Analisi del numero di servizi esposti;`,
    bullet2: `Analisi dei data leak, quali email aziendali e password sottratte ai dipendenti;`,
    bullet3: `Analisi delle potenziali vulnerabilità sfruttabili dall’attaccante.`,
    footer: `Queste tre dimensioni riassumono la possibile superficie di attacco sfruttabile da parte dei cybercriminali.`,
  },
  surveys: {
    title: `Questionari`,
    form: {
      title: `Nuovo Questionario: {engine}`,
      task_name: `Nome Questionario`,
      company: `Azienda`,
      business_name: `Ragione Sociale`,
      office: `Indirizzo Ufficio`,
      email: `Email`,
      save_and_close: `Salva e Chiudi`,
    },
    report: {
      details: `Dettagli`,
      survey: `Questionario`,
      score: `Punteggio`,
      summary: `Executive Summary`,
      level_of_gdpr_compliance: `Livello di Compliance GDPR`,
      data_processing: `Trattamento Dati`,
      data_management: `Gestione dei Dati`,
      privacy_notice: `Informativa Privacy`,
      rights_of_the_data_subject: `Diritti dell'interessato`,
      governance: `Governance`,
      data_protection: `Protezione dei Dati`,
      data_protection_impact_assessment: `Data Protection Impact Assessment`,
      privacy_by_design: `Privacy by Design`,
      question_list: `Lista Domande`,
      question: `Domanda`,
      question_detail: `Dettaglio Domanda`,
      category: `Categoria`,
      description: `Descrizione`,
      solution: `Soluzione`,
      references: `Riferimenti`,
      action: `Azione`,
      functional_cyber_risk: `Rischio Funzionale`,
      technology_risk_management: `Gestione dei rischi tecnologici`,
      data_risk_management: `Gestione dei rischi sui dati`,
      governance_risk_management: `Gestione dei rischi di governance`,
      human_risk_management: `Gestione del rischio umano`,
      technology_risk: `Technology Risk`,
      data_risk: `Data Risk`,
      governance_risk: `Governance Risk`,
      human_risk: `Human Risk`,
      operational_cyber_risk: `Operational Cyber Risk`,
      preventive_security: `<strong>Preventive Security</strong><br>Gap Risk Level`,
      data_break: `<strong>Data Breach</strong><br>Exposure Level`,
      proactive_security: `<strong>Proactive Security</strong><br>Gap Risk Level`,
      ransomware_recovery: `<strong>Ransomware Recovery</strong><br>Exposure Capability`,
      preventive_security_gap_risk: `Preventive Security Gap Risk`,
      proactive_security_gap_risk: `Proactive Security Gap Risk`,
      data_breach_exposure: `Data Break Exposure`,
      ransomware_recovery_exposure: `Ransomware Recovery Exposure`,
    },
    executive_summary: {
      global_risk: `Rischio Complessivo`,
      functional_risk: `Rischio Funzionale`,
      operational_risk: `Rischio Operativo`,
      cyber_risk_assessment_description: `Il Cyber Risk Funzionale ed operativo sono gli indicatori identificati dal servizio di 
          ICT Security Assessment. L'ICT Security Assessment è una metodologia proprietaria di Tinexta Cyber che permette alle aziende di 
          verificare e misurare il proprio livello di rischio cyber e di valutare l'efficacia delle misure di sicurezza adottate. 
          Il servizio di ICT Security Assessment fornisce le indicazioni e le azioni correttive da adottare a livello di Organizzazione, 
          Policy, Personale, Tecnologia e Sistemi di Controllo.`,
      preventive_security: `Prevenzione`,
      data_breach: `Perdita dati`,
      proactive_security: `Sicurezza Proattiva`,
      ransomware_recovery: `Ransomware`,
    },
    functional_cyber_risk: {
      description: `Il Cyber Risk Funzionale determina il livello di rischio cyber aziendale degli asset funzionali 
          del Cyber Security Framework Aziendale:`,
      html: `<h3 class="sub-title">Gestione Rischi Tecnologici</h3>
          <p class="sub-text">Sulla base degli strumenti e soluzioni adottate determina il livello di rischio tecnologico.</p>
          <h3 class="sub-title">Gestione Rischi Dati</h3>
          <p class="sub-text">Misura il livello di esposizione del rischio dei dati aziendali in termini di Business e Data Protection.</p>
          <h3 class="sub-title">Gestione Rischi Governance</h3>
          <p class="sub-text">Identifica il livello di rischio Governance attraverso una attività di Assessment delle policy e procedure adottate.</p>
          <h3 class="sub-title">Gestione Rischio Umano</h3>
          <p class="sub-text">Stabilisce il livello di rischio Umano in base al livello di consapevolezza e awareness e dei dipendenti nei confronti delle possibili minacce di Cyber Security.</p>`,
    },
    operational_cyber_risk: {
      description: `Il Cyber Risk Operativo misura e determina il rischio di subire perdite derivanti 
          dall'inadeguatezza o dalla disfunzione degli asset funzionali. Il rischio operativo determina il rischio di 
          subire attacchi informatici o incidenti informatici in base all'efficienza ed efficacia delle misure di sicurezza 
          preventiva e proattiva. Allo stesso tempo identifica determina la probabilità di rischio di subire un Data Breach 
          e la capacità di recovery da un attacco ransomware.`,
      html: `<h3 class="sub-title">Sicurezza Preventiva</h3>
          <p class="sub-text">Determina con precisione il livello di rischio e vulnerabilità dell'azienda in base dalle misure di Cyber Security preventiva impiegate in quest'area.</p>
          <h3 class="sub-title">Perdita Dati</h3>
          <p class="sub-text">Determina con precisione il livello di rischio e vulnerabilità dell'azienda in base alle misure di Cyber Security proattiva impiegate in quest'area.</p>
          <h3 class="sub-title">Sicurezza Proattiva</h3>
          <p class="sub-text">Misura il livello di esposizione a possibili Data Breach dell'azienda.</p>
          <h3 class="sub-title">Ransomware</h3>
          <p class="sub-text">Identifica il rischio di recupero dati e Business Continuity a seguito di un attacco Ransomware.</p>`,
    },
    add_new: `Nuovo Questionario`,
    survey_gdpr: {
      title: `Al momento non puoi usufruire del servizio di autovalutazione delle misure GDPR.`,
      description: `Per poter compilare l’assessment ed ottenere il report che indica la tua compliance GDPR e le indicazioni correttive, contattaci premendo il pulsante sotto`,
      icon_1: `Compila il questionario rispondendo alle semplici domande riportate`,
      icon_2: `Un Report PDF mostrerà le aree da migliorare con le relative indicazioni.`,
    },
    survey_ict: {
      title: `Al momento non puoi usufruire del servizio di autovalutazione delle misure di sicurezza.`,
      description: `Per poter compilare l’assessment ed ottenere il report che indica la tua postura di sicurezza secondo le principali indicazioni ISO 27001 e del FNCS, contattaci premendo il pulsante sotto`,
      icon_1: `Compila il questionario rispondendo alle semplici domande riportate`,
      icon_2: `Un Report PDF mostrerà le aree da migliorare con le relative indicazioni.`,
    },
  }
};
